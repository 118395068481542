import { NestedValue } from 'react-hook-form';

import { IAdditionFieldsPayload } from 'services/common/common.modal';
import { IOptions } from 'services/settings-service/settings.modal';

export interface JobDetails extends Partial<IAdditionFieldsPayload> {
  description: string;
  assetId: string;
  priority: boolean;
  assigneeId: string;
  jobId: string;
  asset: {
    id: string;
    externalId: string;
    make: {
      name: string;
      id: string;
    };
    model: {
      name: string;
      id: string;
    };
    type: {
      name: string;
      id: string;
    };
    currentLocation: {
      id: string;
      name: string;
    };
  };
  jobType: JobType;
  organisation: {
    id: string;
    name: string;
  };
  user: {
    id: string;
    name: string;
    email: string;
  };
  owner: {
    id: string;
    email: string;
    name: string;
  };
  created: string;
  completed: string;
  status: JobStatus;
  actions: IJobActions[];
  activities: IJobActivity[];
  jobNumber: string;
  transfer?: {
    archived: string;
    id: string;
    isPermanent: boolean;
    fromLocation: {
      id: string;
      name: string;
    };
    toLocation: {
      id: string;
      name: string;
    };
  };
}

export interface IJobActivity {
  created: string;
  data: Record<string, string>;
  jobActivity: {
    id: string;
    name: string;
    iconType: string;
  };
  jobActivityDataId: string;
  performedBy: {
    id: string;
    name: string;
  };
}

export interface IJobActions {
  activityId: string;
  type: FeedType;
  comment: string | null;
  time: string | null;
  parts: ILogParts['parts'] | null;
  attachments: string[];
  user: JobDetails['user'];
  performedBy: JobDetails['user'];
  created: string;
  assetCurrentLocation?: {
    id: string;
    name: string;
  };
  assetHomeLocation?: {
    id: string;
    name: string;
  };
  assetTransferLocation?: {
    id: string;
    name: string;
  };
}

export interface IJob {
  activityId: string;
  type: FeedType;
  comment: string | null;
  time: string | null;
  parts: ILogParts['parts'] | null;
  attachments: string[];
  user: JobDetails['user'];
  performedBy: JobDetails['user'];
  created: string;
  assetCurrentLocation?: {
    id: string;
    name: string;
  };
  assetHomeLocation?: {
    id: string;
    name: string;
  };
  assetTransferLocation?: {
    id: string;
    name: string;
  };
}

export interface AddJobPayload extends Partial<IAdditionFieldsPayload> {
  description: string;
  assetId: NestedValue<IOptions> | null;
  jobTypeId: NestedValue<IOptions> | null;
  priority: boolean;
  assigneeId: NestedValue<IOptions> | null;
  transfer?: {
    locationId: NestedValue<IOptions> | null;
    isPermanent: boolean;
  };
  currentLocation: NestedValue<IOptions> | null; // FE Purpose
}

export interface JobActionsForm {
  assigneeId: NestedValue<IOptions> | null;
  status: JobStatus;
}

export enum JobTypePredefined {
  service = 'Service',
  repair = 'Repair',
  theft = 'Theft',
  assembly = 'Assembly',
  recovery = 'Recovery',
  transfer = 'Transfer',
}

export interface JobType {
  name: string | JobTypePredefined;
  id: string;
  iconType: string;
}

export enum JobStatus {
  inprogress = 'in-progress',
  cancel = 'cancel',
  closed = 'closed',
  open = 'open',
}

export enum FeedType {
  createJob = 'create_job',
  comment = 'comment',
  parts = 'add_parts',
  time = 'log_time',
  complete = 'close_job',
  assignment = 'assignment',
  cancel_job = 'cancel_job',
}

export interface ILogComment {
  comment: string;
}
export interface ILogTime {
  time: string;
}

export interface ILogParts {
  parts: {
    partId: string;
    partName: string;
    action: string;
    categoryName: string;
  }[];
}
